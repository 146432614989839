import { Link } from "gatsby";
import React from "react";
import "./header.css";

const Header = () => (
  <header>
    <Link to="/">
      <span>
          <h1>Olivia Wright</h1>
          <p>
            <span role="img" aria-label="computer emoji">&#x1F4BB;</span> 
            <span role="img" aria-label="plant emoji">&#x1F33F;</span>
            <span role="img" aria-label="camera emoji">&#x1F4F8;</span>
          </p>
      </span>
    </Link>
    
    <ul>
      <li><Link to="/#projects">Projects</Link></li>
      <li><Link to="/#contact">Contact</Link></li>
      <li><Link to="/blogs">Posts</Link></li>
    </ul>
  </header>
)

export default Header